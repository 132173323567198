import { Injectable } from '@angular/core';
import { Jwtsub } from '../models/jwtsub';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserSession } from '../models/usersession';
import { FWTable } from '../models/fwtable';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router
   ) {
    this.jwt = localStorage.getItem('jwt');
    if (this.jwt !== null) {
      this.isLoggedIn = true;
    }
    }

  public isLoggedIn = false;
  public loggingIn = false;
  public jwt: string = null;
  public user: string = null;
  public resthost = 'https://monitor.secureitng.com';
  public session: UserSession;
  public fws: Array<FWTable>;
  public message = '';


  // store the URL so we can redirect after logging in
  redirectUrl: string;

  login(username: string, password: string, mfa: string) {
    this.message = 'Validating';
    this.loggingIn = true;
    this.http.post(this.resthost + '/login', {username, password, mfa}).subscribe(
      (data: Jwtsub ) => {
        this.isLoggedIn = true;
        this.jwt = data.token;
        this.user = username;
        localStorage.setItem('jwt', this.jwt);
        let redirect = this.redirectUrl ? this.router.parseUrl(this.redirectUrl) : '/';
        if (data.code === '1') {
          console.log('User needs to enroll in MFA');
          redirect = this.redirectUrl ? this.router.parseUrl(this.redirectUrl) : '/enrollmfa';
        } else {
          console.log('User is enrolled and passed MFA.');
        }
        this.loggingIn = false;
//        this.session.username = username;
        this.router.navigateByUrl(redirect);
      },
      (error) => {
        console.log('Failed login:', error);
        this.loggingIn = false;
        this.message = 'Invalid credentials';
      }
    );
  }

  logout(): void {
    this.isLoggedIn = false;
    this.jwt = null;
    localStorage.removeItem('jwt');
    this.user = null;
    this.message = '';
    const redirect = '/login';
    this.router.navigateByUrl(redirect);

  }


}

<div>
  <google-chart #chart
    (click)="onClick()"
    [type]="gchart.type"
    [data]="gchart.data"
    [options]="gchart.options">
  </google-chart>
<!--  {{ data | json }} -->
  <!--  [columnNames]="gchart.columnNames"> -->
  </div>

import { Component, OnInit } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss']
})
export class GraphsComponent implements OnInit {
  public sideopen = false;

  constructor(
    private rest: RestService,
    public auth: AuthService,
  ) { }

  ngOnInit() {
    this.rest.getFWs().subscribe(data => {
      data.sort((s1, s2) => {
        if(s1.Name > s2.Name) {
          return 1;
        } else {
          return -1;
        }
      });
      this.auth.fws = data;
      console.log("Got FWs:",this.auth.fws);
      this.rest.getusersession().subscribe(session => {
        this.auth.session = session;
        console.log("Got Session:",this.auth.session);
        // now we have the FWs.  We should already have the user profile. Let's hide the fws per the profile
        session.hidefws.forEach(hide => {
          this.auth.fws.find(el => el.Name === hide).hide = true;
        });
      },
      (error) => {
        console.log('Error getting session.  Setting to blank.', error);
        this.auth.session = {
          username: this.auth.user,
          hidefws: []
        };
      });
    });
  }
}

<div class="login">
  <div class="container">
    <img src="assets/logo.svg" alt="logo">
    <p>{{ auth.message }}</p>
    <form [formGroup]="loginForm" (ngSubmit)="onconfirm()">
      <p> Please use an MFA app<br>
      (e.g Google Authenticator)<br>
      to register using this QR Code<br>
      <img *ngIf='mfaenroll' [src]='mfaenroll'><br>
      Then enter the MFA code below and hit confirm.<br></p><br>
      <div class="form-group">
        <label for="mfa">MFA :</label>
        <input type="text" formControlName="mfa" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mfa.errors }" />
        <div *ngIf="submitted && f.mfa.errors" class="invalid-feedback">
            <div *ngIf="f.mfa.errors.required">MFA is required</div>
        </div>
    </div>{{ message }}<br>
    <div class="form-group">
          <button [disabled]="auth.loggingIn" class="btn btn-primary">
              <span *ngIf="auth.loggingIn" class="spinner-border spinner-border-sm mr-1"></span>
              Confirm
          </button>
      </div>
  </form>
  </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FWTable } from 'src/app/models/fwtable';
import { RestService } from '../../services/rest.service';
import { AuthService } from '../../services/auth.service';
import { UserSession } from '../../models/usersession';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() fws: Array<FWTable>;
  @Output() fwsChange: EventEmitter<FWTable> = new EventEmitter<FWTable>();

  constructor(
    private rest: RestService,
    public auth: AuthService
  ) { }

  ngOnInit() {
  }
  updateprofile() {
    this.auth.session.hidefws = [];
    this.fws.forEach(element => {
      if (element.hide) {
        console.log(element.Name, " is hidden.")
        this.auth.session.hidefws.push(element.Name);
      }
    });
    console.log('Updating profile with array:',this.auth.session.hidefws);
    this.rest.putusersession(this.auth.session).subscribe(data => {
      console.log("update session got:", data);
    });
  }
}

<div><app-header
  [(sideopen)] = "sideopen"
  ></app-header>
<mat-sidenav-container
class="mainscreen" >
  <mat-sidenav #sidenave mode="side" [(opened)]="sideopen">
    <app-sidebar
      [(fws)] = "auth.fws"
    ></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="wrap">
      <ng-container layout="column" *ngFor = "let opt of auth.fws">
      <app-graph
      *ngIf = "!opt.hide"
    [fw] = opt></app-graph>
  </ng-container>
  </div>
    </mat-sidenav-content
    >
</mat-sidenav-container
>
</div>


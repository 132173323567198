import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() sideopen: boolean;
  @Output() sideopenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private rest: RestService,
    public auth: AuthService
  ) { }

  ngOnInit() {
  }

  onLogout() {
    this.auth.logout();
  }

  onSidebarToggle() {
    this.sideopen = !this.sideopen;
    this.sideopenChange.emit(this.sideopen);
  }
}

import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { FWTable } from 'src/app/models/fwtable';
import { GraphData } from 'src/app/models/graphdata';
import { RestService } from 'src/app/services/rest.service';
import { GoogleChartComponent } from 'angular-google-charts';
import { timer } from 'rxjs';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit, OnDestroy {

  @Input() fw: FWTable;
  @ViewChild('chart') chart: GoogleChartComponent;

  public flipDiv: boolean;
  public updating = true;  // false: don't update view (so you don't pull back to current while looking at history when the data updates)
  public sankeydate: Date;
  public calpopped = false;
  public inhistory = false;
  public maximized = false;
  public cardclass = 'card';
  private timer;

  public gchart = {
    title: 'FW info',
    columnNames: ['', 'Mb/s', 'Mem%', 'CPULoad', 'ClientVPNs'],
    type: 'LineChart',
    data: [
      [new Date(), 0, 0, 0, 0]
    ],
    options: {
      backgroundColor: 'transparent',
      legend: { position: 'top', alignment: 'start', textStyle: {color: '#ffffff'} },
      chartArea: {
        width: '80%',
        height: '80%'
      },
      explorer: {
        axis: 'horizontal',
        keepInBounds: true,
        maxZoomIn: (1 / 24),
        maxZoomOut: 1
       },
      series: {
        0: {targetAxisIndex: 0},
        1: {targetAxisIndex: 1},
        2: {targetAxisIndex: 2},
        3: {targetAxisIndex: 3}
      },
      vAxes: {
      0: {
          title: 'Mbits / sec',
          titleTextStyle: {color: '#ffffff'},
          gridlines: {
            count: 0
          },

        },
      1: {
          title: '%',
          titleTextStyle: {color: '#ffffff'},
          viewWindow: {
            max: 100,
            min: 0
           },
        },
      2: {
//          title: 'x/10',
          ticks: [],
          viewWindow: {
            max: 10,
            min: 0
           },
           gridlines: {
            count: 3
          },

        },
      3: {
          title: '#',
          ticks: [],
          viewWindow: {
            max: 500,
            min: 0
            },
            gridlines: {
            count: 3
          },
        },
      },
      hAxes: {
        titleTextStyle: {color: '#ffffff'},
      },
      hAxis: {
//        textPosition: 'none',
        textStyle: {
          color: '#ffffff'
        },
        baselineColor: '#ffffff',
        // viewWindow: {
        //   min: Date.now() - (1000 * 60 * 60),
        //   max: Date.now()
        // }
      },
      vAxis: {
        //        textPosition: 'none',
                textStyle: {
                  color: '#ffffff'
                },
                gridlines: {
                    color: '#ffffff',
//                    count: 3
                },
                baselineColor: '#ffffff'
              }

    },
  };

  constructor(
    private rest: RestService
  ) { }

  ngOnInit() {
    // We need to set the CPU load scale since it varies per model
    // default (10) is set above in the object def, but overridden here for specific models
    // it's reported as the number of active tasks, so really doesn't map to CPU% well.
    //

    // none of this is required any more since we now have proper CPU info.
//    switch (this.fw.model) {
//      case 'F18': {
//        this.gchart.options.vAxes[2].viewWindow.max = 6;
//        break;
//      }
//      case 'F200b': {
//        this.gchart.options.vAxes[2].viewWindow.max = 6;
//        break;
//      }
//      case 'F280': {
//        this.gchart.options.vAxes[2].viewWindow.max = 8;
//        break;
//      }
//      case 'F380': {
//        this.gchart.options.vAxes[2].viewWindow.max = 60;
//        break;
//      }
//      case 'F400b': {
//        this.gchart.options.vAxes[2].viewWindow.max = 40;
//        break;
//      }
//      case 'F600c': {
//        this.gchart.options.vAxes[2].viewWindow.max = 50;
//        break;
//      }
//    }
    // instead, we just need this.
    this.gchart.options.vAxes[2].viewWindow.max = 100;


    // We need to get the last hour's data
    this.rest.getLastHour(this.fw.Name).subscribe(d => {
      d.reverse().forEach(s => {
        this.gchart.data.push([
          new Date(s.datetime),
          s.mbps,
          s.memory,
          s.cpu,
          s.clientvpn
          ]);
        });
      this.gchart.data.shift();
      this.gchart.data = [...this.gchart.data];
      });

    // set an interval to update the graph every minute
    this.timer = setInterval(() => {
      if (!this.inhistory){  // if we're viewing history we don't need to get current data
      this.rest.getMostRecent(this.fw.Name).subscribe(s => {
        const t = [
          new Date(s[0].datetime),
          s[0].mbps,
          s[0].memory,
          s[0].cpu,
          s[0].clientvpn
        ];
        this.gchart.data.push(t);
        this.gchart.data.shift();
        if (this.updating) {
          this.gchart.data = [...this.gchart.data];
          this.sankeydate = s[0].datetime;  // when this changes the session component should be notified and get itself new data.
        }
      });
       }  // end if !inhistory
      }, 60000); // end timer
    // end of ngOnInit
  }

  ngOnDestroy() {
    // Will clear when component is destroyed e.g. route is navigated away from.
    clearInterval(this.timer);
 }

  getChartRange() {
//    const chartLayout = this.chart.wrapper.getChart().getChartLayoutInterface();
//    const chartBounds = chartLayout.getChartAreaBoundingBox();
    const chartBounds = this.chart.chartWrapper.getChart().getContainer().getBoundingClientRect();
    return {
//        min: chartLayout.getHAxisValue(chartBounds.left),
//       max: chartLayout.getHAxisValue(chartBounds.left + chartBounds.width),
//        len: chartLayout.getHAxisValue(chartBounds.width)
      min: chartBounds.left,
      max: chartBounds.left + chartBounds.width,
      len: chartBounds.width
    };
  }

  graphSelect(c) {
    console.log('Graph selected.  Event:', c);
    this.sankeydate = new Date(this.gchart.data[c.selection[0].row][0]);
    this.flipDiv = !this.flipDiv;
    this.updating = false;
  }

  graphClick(c) {
//    console.log('Graph clicked.  Event:',c);
  }

  trafficOverview() {

  }

  flipcard() {
    this.flipDiv = !this.flipDiv;
//    this.updating = true;
  }

  onPlay() {
    this.updating = true;
    this.gchart.data = [...this.gchart.data];
    this.sankeydate = new Date(this.gchart.data[this.gchart.data.length - 1][0]);
    if (this.inhistory) {  // we were looking at history, so now need to reload the current hour
      this.rest.getLastHour(this.fw.Name).subscribe(d => {
        this.gchart.data = [];
        d.reverse().forEach(s => {
          this.gchart.data.push([
            new Date(s.datetime),
            s.mbps,
            s.memory,
            s.cpu,
            s.clientvpn
            ]);
          });
        this.gchart.data.shift();
        this.gchart.data = [...this.gchart.data];
        this.inhistory = false;
        });
    }
  }

  onDate() {
    this.calpopped = !this.calpopped;
    // date selector goes here.
  }

  onPickDate(event) {
    this.calpopped = false;
    this.flipDiv = false;
    this.rest.gettrafficdate(this.fw.Name, event).subscribe(d => {
      this.gchart.data = [];
      d.reverse().forEach(s => {
        this.gchart.data.push([
          new Date(s.datetime),
          s.mbps,
          s.memory,
          s.cpu,
          s.clientvpn
          ]);
        });
      this.gchart.data.shift();
      this.gchart.data = [...this.gchart.data];
      this.inhistory = true;
      this.updating = false;
      });

  }

  togglebig() {
    this.maximized = !this.maximized;
    if (this.maximized) {
      this.cardclass = 'cardmax';
      console.log('big!');
    } else {
      this.cardclass = 'card';
      console.log('small!');
    }
//    this.chart.redraw();
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service'
import { RestService } from '../../services/rest.service'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enrollmfa',
  templateUrl: './enrollmfa.component.html',
  styleUrls: ['./enrollmfa.component.scss']
})
export class EnrollmfaComponent implements OnInit {
  loginForm: UntypedFormGroup;
  public submitted;
  public message = "Enter code:";
  public mfaenroll: any;

  constructor(
    public auth: AuthService,
    private formBuilder: UntypedFormBuilder,
    private rest: RestService,
    private sani: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      mfa:      [''],
    });
    this.rest.getmfaenroll().subscribe(baseImage => {
      let objectURL = 'data:image/png;charset=utf-8;base64,' + baseImage;
      this.mfaenroll = this.sani.bypassSecurityTrustUrl(objectURL);
    });
  }

  onconfirm() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    console.log("Confirming code:", this.f.mfa.value);
    this.message = 'Verifying.';
    this.rest.mfaconfirm("ignore", this.f.mfa.value).subscribe(data => {
      if (data) { // mfa confirmed
        this.message = 'Code good.';
        this.router.navigateByUrl('/');

      } else { // mfa unconfirmed
        this.message = 'Code incorrect.';
      }
    });
  }

  get f() { return this.loginForm.controls; }
}

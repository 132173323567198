import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FWTable } from '../models/fwtable';
import { Observable } from 'rxjs';
import { FirewallData } from '../models/graphdata';
import { Sessiondata } from '../models/sessiondata';
import { UserSession } from '../models/usersession';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  serverUrl = this.auth.resthost + '/graphs';

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Bearer ' + this.auth.jwt
    })
  };

  getFWs(): Observable<Array<FWTable>> {
    this.resetjwt();
    const url: string = this.serverUrl + '/getfwlist';
    return this.http.get<Array<FWTable>> (url, this.httpOptions);
//     return this.http.get<Array<FWTable>> (url);
  }

  getLastHour(fw: string): Observable<Array<FirewallData>> {
    this.resetjwt();
    const url: string = this.serverUrl + '/getlasthour/' + fw;
    return this.http.get<Array<FirewallData>> (url, this.httpOptions);
//    return this.http.get<Array<FirewallData>> (url);
  }

  getLastDay(fw: string): Observable<Array<FirewallData>> {
    this.resetjwt();
    const url: string = this.serverUrl + '/getlastday/' + fw;
    return this.http.get<Array<FirewallData>> (url, this.httpOptions);
//    return this.http.get<Array<FirewallData>> (url);
  }

  getMostRecent(fw: string): Observable<FirewallData> {
    this.resetjwt();
    const url: string = this.serverUrl + '/getmostrecent/' + fw;
    return this.http.get<FirewallData> (url, this.httpOptions);
//    return this.http.get<FirewallData> (url);
  }

  getsessions(fw: string): Observable<Array<Sessiondata>> {
    this.resetjwt();
    const url: string = this.serverUrl + '/getsessions/' + fw;
    return this.http.get<Array<Sessiondata>> (url, this.httpOptions);
//    return this.http.get<Array<Sessiondata>> (url);
  }

  getsessionsdate(fw: string, date: Date): Observable<Array<Sessiondata>> {
    this.resetjwt();
    let url: string;
    if (date === undefined) {
      url = this.serverUrl + '/getsessions/' + fw;
    } else {
      let ds: string;
      if (typeof(date) === 'string') {
        ds = date;
      } else {
        ds = date.toISOString();
      }
      url = this.serverUrl + '/getsessions/' + fw + '/' + encodeURIComponent(ds);
    }
    return this.http.get<Array<Sessiondata>> (url, this.httpOptions);
//    return this.http.get<Array<Sessiondata>> (url);
  }

  gettrafficdate(fw: string, date: Date): Observable<Array<FirewallData>> {
    this.resetjwt();
    let url: string;
    if (date === undefined) {
      url = this.serverUrl + '/getlastday/' + fw;
    } else {
      let ds: string;
      if (typeof(date) === 'string') {
        ds = date;
        console.log('GetTrafficDate was passed a string.  Trying anyway.');
      } else {
        ds = date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate();
      }
      url = this.serverUrl + '/getday/' + fw + '/' + encodeURIComponent(ds);
      console.log('URL:', url);
    }
    return this.http.get<Array<FirewallData>> (url, this.httpOptions);
//    return this.http.get<Array<FirewallData>> (url);
  }

  getusersession(): Observable<UserSession> {
    this.resetjwt();
    const url = this.serverUrl + '/getusersession';
    return this.http.get<UserSession> (url, this.httpOptions);
//    return this.http.get<Array<Sessiondata>> (url);
  }

  putusersession(data: UserSession): Observable<UserSession> {
    this.resetjwt();
    const url = this.serverUrl + '/putusersession';
    return this.http.put<UserSession> (url, data, this.httpOptions);
  }

  resetjwt() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + this.auth.jwt
      })
    };
  }

  getmfaenroll(): Observable<any> {
    const url = this.serverUrl + '/mfaenroll';
    console.log('Getting image.');
    return this.http.get<any> (url, this.httpOptions);
  }

  mfaconfirm(username: string, mfa: string): Observable<boolean> {
    const url = this.serverUrl + '/mfaconfirm';
    console.log('Confirming MFA code.');
    return this.http.put<boolean> (url, {username, mfa}, this.httpOptions);
  }
}




import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../components/login/login.component';
import { GraphsComponent } from '../components/graphs/graphs.component';
import { AuthGuard } from '../guards/auth.guard';
import { EnrollmfaComponent } from '../components/enrollmfa/enrollmfa.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'enrollmfa', component: EnrollmfaComponent },
  { path: '', component: GraphsComponent, canActivate: [AuthGuard] },
 // { path: 'reports', component: ReportsComponet, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="login">
  <div class="container">
    <img src="assets/logo.svg" alt="logo">
    <p>{{ auth.message }}</p>
    <form [formGroup]="loginForm" (ngSubmit)="onlogin()">
      <div class="form-group">
          <label for="username">Username :</label>
          <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">Username is required</div>
          </div>
      </div>
      <div class="form-group">
          <label for="password">Password :</label>
          <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
          </div>
      </div>
      <div class="form-group">
        <label for="mfa">MFA :</label>
        <input type="text" formControlName="mfa" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mfa.errors }" />
        <div *ngIf="submitted && f.mfa.errors" class="invalid-feedback">
            <div *ngIf="f.mfa.errors.required">MFA is required</div>
        </div>
    </div>
    <div class="form-group">
          <button [disabled]="auth.loggingIn" class="btn btn-primary">
              <span *ngIf="auth.loggingIn" class="spinner-border spinner-border-sm mr-1"></span>
              Login
          </button>
      </div>
  </form>
  </div>
</div>

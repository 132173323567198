<mat-card
  [ngClass]="cardclass"
  md-theme="default">
  <mat-card-header>

    <!-- <mat-form-field>
      <input matInput [matDatepicker]="picker" placeholder="Choose a date" class="hidden">
      <mat-datepicker-toggle matSuffix [for]="picker" color="primary"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field> -->

    <div class="calpopbut"><button mat-mini-fab (click)="onDate()" class="card-container-right" color="primary"><mat-icon>calendar_today</mat-icon></button></div>
    <div [class.calpopon]="calpopped" [class.calpopoff]="!calpopped">
      <mat-calendar
      (selectedChange)="onPickDate($event)">
      </mat-calendar>
    </div>
    <div><button mat-mini-fab (click)="onPlay()" class="card-container-right" color="primary" [disabled]="updating"><mat-icon>play_arrow</mat-icon></button></div>
    <div><button mat-mini-fab (click)="flipcard()" class="card-container-right" color="primary"><mat-icon>swap_horizontal</mat-icon></button></div>
    <!-- <div><button mat-mini-fab (click)="togglebig()" class="card-container-right" color="primary"><mat-icon>fullscreen</mat-icon></button></div> -->
    <mat-card-title>
      {{ fw.Name }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ fw.model }} {{ fw.phionVersion.replace('PH-GWAY-','') }} [{{ fw.serial }}]
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
      <ngx-flip [flip]="flipDiv">
        <div front class="wrap" dark>
          <google-chart #chart
          (select)='graphSelect($event)'
          (click)="graphClick($event)"
          [type]="gchart.type"
          [data]="gchart.data"
          [options]="gchart.options">
        </google-chart>
        </div>
        <div back>
          <app-sessions
            [fw]="fw"
            [date]="(sankeydate)"
            ([flipDiv])="flipDiv"
            ></app-sessions>
        </div>
      </ngx-flip>
  </mat-card-content>
</mat-card>
<!-- line 37        [columnNames]="gchart.columnNames"> -->

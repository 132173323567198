import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FWTable } from '../../models/fwtable';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit, OnChanges {
  @Input() fw: FWTable;
  @Input() date: Date;
  @Input() flipDiv: boolean;

  public gchart = {
    title: 'Session info',
    columnNames: ['User/IP', 'Destination', 'data'],
    type: 'Sankey',
    data: [[ 'Source', 'Destination', 100 ]],
    options: {
      backgroundColor: 'transparent',
      legend: { position: 'top', alignment: 'start', textStyle: {color: '#ffffff'} },
      chartArea: {
        width: '80vw',
        height: '70vh'
      },
      sankey: {
        iterations: 1,
        node: {
          label: {
            color: '#ffffff'
          }
        }
      }
    },
  };


  constructor(
    private rest: RestService
  ) { }

  ngOnInit() {
    this.rest.getsessions(this.fw.Name).subscribe(d => {
      this.gchart.data.shift();
      d.forEach(v => {
        this.gchart.data.push([
          v.Source,
          v.Destination,
          v.Weight
        ]);
      });
      this.gchart.data = [...this.gchart.data];
    });
  }

  ngOnChanges() {
    // date bound from parent changed, so get the new data.
    const newdata = [];
    this.rest.getsessionsdate(this.fw.Name, this.date).subscribe(d => {
      d.forEach(v => {
        newdata.push([
          v.Source,
          v.Destination,
          v.Weight
        ]);
      });
      this.gchart.data = newdata;
    });
  }

  onClick() {
    this.flipDiv = !this.flipDiv;
  }
}
